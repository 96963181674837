type DateArgs = any;

export function nearestUtcDate(dates: (string | Date | number)[], targetDate: (Date | string | number)): Date | string | number {
    return dates.reduce((nearestDate, currentDate) => {
        return Math.abs(getUtcTime(currentDate) - getUtcTime(targetDate)) < Math.abs(getUtcTime(nearestDate) - getUtcTime(targetDate))
            ? currentDate
            : nearestDate;
    }, dates[0]);
}

export function utcDate(...args: DateArgs): Date {
    const date = new Date(...args as Parameters<typeof Date>);
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}


export function utcISOString(...args: DateArgs): string { return utcDate(...args).toISOString() }

export function getUtcTime(...args: DateArgs): number { return utcDate(...args).getTime() }