import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

// this is the mocking of backend server ,uncomment it in local development 
if (process.env.REACT_APP_USE_MOCKS === 'true') {
  try {
    require('./mocks/handlers/index')
    console.log('Mock handlers loaded in development.');
  } catch (error) {
      console.error('Error loading mock handlers:', error);
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <FpjsProvider
      loadOptions={{
        apiKey: process.env.REACT_APP_FINGERPRINT_JS_API_KEY!,
        endpoint: process.env.REACT_APP_FP_ENDPOINT,
        scriptUrlPattern: `${process.env.REACT_APP_FP_ENDPOINT}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`
      }}
    >
      <App />
    </FpjsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
