import {
    createContext,
    useContext,
    useState,
    ReactNode,
    useCallback,
} from "react";
import { useAppSelector } from "../app/hooks";
import { router } from "../app/router";

interface AuthDialogContextType {
    signUpOpen: boolean;
    signInOpen: boolean;
    handleOpenSignUp: () => void;
    handleOpenSignIn: (redirectUrl?: string | unknown) => void;
    handleClose: () => void;
}

const AuthDialogContext = createContext<
    AuthDialogContextType | undefined
>(undefined);

interface AuthDialogProviderProps {
    children: ReactNode;
}

export const AuthDialogProvider = ({ children }: AuthDialogProviderProps) => {
    const isUserLoggedIn = useAppSelector((state) => state.auth.isUserLoggedIn);
    const [signUpOpen, setSignUpOpen] = useState(false);
    const [signInOpen, setSignInOpen] = useState(false);
    const [redirectPath, setRedirectPath] = useState<string | undefined>();

    const handleOpenSignIn: AuthDialogContextType['handleOpenSignIn'] = useCallback((url) => {
        if (url && typeof url === 'string') {
            setRedirectPath(url)
        }
        setSignInOpen(true);
        setSignUpOpen(false);
    }, []);

    const handleOpenSignUp = useCallback(() => {
        setSignInOpen(false);
        setSignUpOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setSignInOpen(false);
        setSignUpOpen(false);
        setRedirectPath(undefined)
        if(redirectPath && isUserLoggedIn) {
            router.navigate(redirectPath)
        }
    }, [isUserLoggedIn, redirectPath])

    return (
        <AuthDialogContext.Provider
            value={{
                signUpOpen,
                signInOpen,
                handleOpenSignUp,
                handleOpenSignIn,
                handleClose
            }}
        >
            {children}
        </AuthDialogContext.Provider>
    );
};

export const useAuthDialog = (): AuthDialogContextType => {
    const context = useContext(AuthDialogContext);
    if (!context) {
        throw new Error("useAuthDialog must be used within a AuthDialogProvider");
    }
    return context;
};
