import { CircularProgress } from "@mui/material";
import { ReactNode, Suspense } from "react";

const GlobalLoader = ({ children }: { children: ReactNode }) => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
export default GlobalLoader;
