export const ROUTES = {
  index: "/",
  tickers: {
    index: "/tickers",
    byId: (id = ":id") => `/tickers/${id}`,
  },
  profile: {
    index: "/profile",
    byUsername: (username: string) => `/profile/${username}`,
  },
  wildCard: "*"
};
