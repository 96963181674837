
import { mock } from "../mock";

mock.onGet(new RegExp('/api-auth/user/*')).reply(() => {
    const res = {
        name: "Alex Hussain",
        username: "alexhussain",
        bio: "Avid market enthusiast. I turn financial insights into winning predictions.",
        memberSince: '2024'
    }
    return [200, res]
})
