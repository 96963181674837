import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_EINSTEIN_URL,
});

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve token (e.g., from localStorage 
    const token = localStorage.getItem("memeStockToken"); // Replace this with your token retrieval logic

    if (token) {
      // Attach token to Authorization header if available
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle the error and pass the entire error response
    let errorData: any = {
      message: 'An unknown error occurred',
      errors: [],
    };

    if (error.response) {
      // Check if the response has the specific error structure
      if (error.response.data.errors && Array.isArray(error.response.data.errors)) {
        errorData = error.response.data;
      } else {
        // Default error message from response
        errorData.message = error.response.data.message || errorData.message;
      }
    } else if (error.request) {
      // No response was received
      errorData.message = 'No response received from server';
    } else {
      // Something else happened
      errorData.message = error.message;
    }

    return Promise.reject(errorData);
  }
);

export default axiosInstance;