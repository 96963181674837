import { ISearchTicker, ISearchUser } from "../../app/types/search";
import { mock } from "../mock";


function createUserData({ email, name }: ISearchUser): ISearchUser {
    return { email, name };
}
function createTickerData({ symbol, name }: ISearchTicker): any {
    return { symbol, name };
}

const userRows: Array<ISearchUser> = [
    createUserData({email:'1',name:'User 1'}),
    createUserData({email:'2',name:'User 2'}),
    createUserData({email:'3',name:'User 3'}),
    createUserData({email:'4',name:'User 4'}),
    createUserData({email:'5',name:'User 5'}),
];


const cryptoTrickersRows: Array<ISearchTicker> = [
    createTickerData({ symbol: 'GEM', name: "Crypto 1"}),
    createTickerData({ symbol: 'GEM', name: "Crypto 2"}),
    createTickerData({ symbol: 'GEM', name: "Crypto 3"}),
    createTickerData({ symbol: 'GEM', name: "Crypto 4"}),
    createTickerData({ symbol: 'GEM', name: "Crypto 5"}),
    createTickerData({ symbol: 'MSFT', name: "Crypto 6"}),
];

const stockTrickersRows: Array<ISearchTicker> = [
    createTickerData({ symbol: 'AAPL', name: "Stock 1" }),
    createTickerData({ symbol: 'GEM', name: "Stock 2" }),
    createTickerData({ symbol: 'AMZN', name: "Stock 3" }),
    createTickerData({ symbol: 'AMZN', name: "Stock 4" }),
    createTickerData({ symbol: 'AMZN', name: "Stock 5" }),
    createTickerData({ symbol: 'MSFT', name: "Stock 6" }),
];


mock.onGet(new RegExp(`/api/search/*`) ).reply(() => {
    return [200, {
        categories: {
            users: userRows,
            stocks: stockTrickersRows,
            crypto: cryptoTrickersRows,
        }
    }]
});
