
import { OtpVerifyResponse, SignInResponse } from "../../app/types/auth";
import { mock } from "../mock";



mock.onPost('/api/signup/').reply(() => {
    // return [400, {
    //     errors: [{
    //         path: "",
    //         msg: "test general error"
    //     },{
    //         path:"email",
    //         msg:"already exists"
    //     }
    // ]
    // }]
    return [200]
})
mock.onGet('/api-auth/user/').reply(() => {

    const res = {
        username: 'somefakeusername',
        name: 'someFakeDisplayName',
        description: 'some fake description about the user to put in their bio',
        score: '78'
    }
    return [200, res]
})

mock.onPost('/api/signin/').reply(() => {
    const res: SignInResponse = {
        idToken: "idToken",
        accessToken: "string",
        refreshToken: "refreshToken",
        name: "testtttt",
        username: "testttt2"
    }
    return [200, res]
})
mock.onPost('/api/reset-password/').reply(() => {
    // return [400, {
    //     errors: [{
    //         path: "username",
    //         msg: "wrong username"
    //     },
    //     {
    //         path: "",
    //         msg: "general message "
    //     }
    // ]
    // }]
    return [200]
})

mock.onPost('/api/signup/confirm/').reply((config) => {
    const body = JSON.parse(config.data);

    if (body.verificationCode !== '123456') {
        return [400, {
            errors: [
                {
                    path: 'verificationCode',
                    msg: 'invalid otp'
                }
            ]
        }]
    }
    const res: OtpVerifyResponse = {
        idToken: "idToken",
        accessToken: "string",
        refreshToken: "refreshToken"
    }
    return [200, res]
})

mock.onPost('/api/reset-password/confirm/').reply((config) => {
    return [200,""]
})

mock.onPost('/api-auth/user/').reply((config) => {
    return [200,""]
})
