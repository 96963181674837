import { Box, Container, Link, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

const styles: Record<string, SxProps<Theme>> = {
    borderBottom: { borderBottom: "3px solid #EEEEEE" },
    container: { mx: 0 },
    footerBox: { display: "flex", justifyContent: "space-between", alignItems: "center",  pt:"6rem", pb:"4rem"  },
    logoTypography: { fontFamily: "Nexa", color: "#1E1F24", fontWeight: 700, fontSize: "2.5rem", lineHeight: "2.9rem" },
    link: {
        fontFamily: "Roboto",
        color: "#9E9E9E",
        fontSize: "0.9rem",
        '&:hover': {
            textDecoration: 'underline', // Add hover effect
            cursor: 'pointer', // Change cursor to pointer
        }
    },
    linksBox: {
        display: "flex",
        justifyContent: "flex-end", // Aligns links to the right
        gap: "1rem", // Adds space between links
    },
    disclaimerText: { fontFamily: "Roboto", color: "#9E9E9E", fontSize: "0.9rem" }
};


// Define the component as a functional component with no props
const Footer: React.FC = () => {
    return (
        <Box>
            <Box sx={styles.borderBottom} />
            <Container maxWidth={false} sx={styles.container}>
                <Box sx={styles.footerBox}>
                    <Box>
                        <Typography sx={styles.logoTypography}>MemeStock</Typography>
                    </Box>
                    <Box>
                        {/* <Box sx={{ ...styles.linksBox }}>
                            <Link onClick={() => { }} underline="none" sx={styles.link}>
                                About
                            </Link>
                            <Link onClick={() => { }} underline="none" sx={styles.link}>
                                Privacy
                            </Link>
                            <Link onClick={() => { }} underline="none" sx={styles.link}>
                                Disclaimer
                            </Link>
                            <Link onClick={() => { }} underline="none" sx={styles.link}>
                                Terms
                            </Link>
                        </Box> */}
                        <Typography sx={styles.disclaimerText}>
                            Disclaimer:
                        </Typography>
                        <Typography sx={styles.disclaimerText}>
                            MemeStock is for entertainment purposes only and does not provide financial or investment advice.
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;