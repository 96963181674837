import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#0062FF',
    },
    secondary: {
      main: '#ffffff',
    },
    divider: '#D1D5DB'
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          boxSizing: 'border-box'

        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.button-primary': {
            color: 'white',
          },
          textTransform: 'none',
          '&.button-secondary': {
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          },
        },
      },
    },
    MuiContainer:{
      defaultProps:{
        maxWidth:false
      },
      styleOverrides:{
        root:{
          paddingLeft:'3.5rem !important',
          paddingRight:'3.5rem !important'
        }
      }
    }
  },
});

