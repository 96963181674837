import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { authSlice } from '../app/slices/authSlice';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  let memeStockToken = useAppSelector((state: any) => state.auth.memeStockToken);
  if (!memeStockToken) {
    memeStockToken = localStorage.getItem("memeStockToken")
  }
  const dispatch = useAppDispatch();

  if (memeStockToken) {
    return <>{children}</>
  }

  dispatch(authSlice.actions.setShowSignInPopup(true))

  return <Navigate to="/" /> // TODO: need to create route and component for it 

};

export default PrivateRoute;