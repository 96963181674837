import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@emotion/react";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { theme } from "./app/theme";
import { router } from "./app/router";
import { store } from "./app/store";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { AuthDialogProvider } from "./context/auth-dialog-context";
import { Fragment, ReactNode, useEffect } from "react";
import { getUserData } from "./app/slices/authSlice";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import Footer from "./components/Footer";
const queryClient = new QueryClient()

const InitialLoader = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const memeStockToken = useAppSelector((state: any) => state.auth.memeStockToken);

  useEffect(() => {
    if (memeStockToken) {
      dispatch(getUserData());
    }
  }, [dispatch, memeStockToken]);

  return <Fragment>{children}</Fragment>
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthDialogProvider>
            <InitialLoader>
              <RouterProvider router={router} />
            </InitialLoader>
          </AuthDialogProvider>
        </QueryClientProvider>
        <Footer />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
