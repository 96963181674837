import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { ApiErrorResponse } from "../types/general";
import { MarketType, SortField, TickerObject } from "../types/tickers";







export interface TickerState {
    isLoading: boolean;
    errors: ApiErrorResponse | ApiErrorResponse[] | null;
    tickers: Array<TickerObject>
}


const initialState: TickerState = {
    isLoading: false,
    errors: null,
    tickers: [],

}




export const getTickers = createAsyncThunk('/tickers/list',
    async ({ marketType, sortField  }: { marketType: MarketType, sortField?: SortField }, { rejectWithValue, dispatch }): Promise<Array<TickerObject> | ApiErrorResponse | undefined> => {
        try {
            const response = await axiosInstance.get(`/api/tickers/${marketType}/?sort=${sortField}`);
            return response.data

        } catch (error: any) {
            return rejectWithValue(error.errors) as any
        }
    }
)

const tickerSlice = createSlice({
    name: 'tickers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTickers.pending, (state) => {
                state.isLoading = true;
                state.errors = null;
            })
            .addCase(getTickers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.errors = null;
                state.tickers = (action.payload as any)
            })
            .addCase(getTickers.rejected, (state, action) => {
                state.isLoading = false;
                (state.errors) = action.payload as ApiErrorResponse;
            })


    }
})


export default tickerSlice.reducer;