import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { ApiErrorResponse } from "../types/general";
import { OtpVerifyResponse, OtpVerifyPayload, SignUpPayload, SignInPayload, SignInResponse, ResetPasswordPayload, ResetPasswordConfirmPayload, UpdateUserProfilePayload } from "../types/auth";







export interface AuthState {
    memeStockToken: string | null;
    isLoading: boolean;
    errors: ApiErrorResponse | ApiErrorResponse[] | null;
    isOtpSent: boolean,
    isUserLoggedIn: boolean,
    isUserDataUpdated: boolean,
    showSignInPopup: boolean,
    showResetPasswordPopup: boolean,
    isPasswordReset: boolean,
    isResetPasswordOTPSent: boolean,
    userData: UserData | null
}

export interface UserData {
    username: string,
    name: string,
    description: string,
    score: number,
    phone?: string
}


const initialState: AuthState = {
    memeStockToken: localStorage.getItem("memeStockToken") ?? null,
    isLoading: false,
    errors: null,
    isOtpSent: false,
    showSignInPopup: false,
    isPasswordReset: false,
    showResetPasswordPopup: false,
    isResetPasswordOTPSent: false,
    isUserDataUpdated: false,
    isUserLoggedIn: !!localStorage.getItem("memeStockToken") ?? false,
    userData: null,
}


export const signIn = createAsyncThunk('auth/signin',

    async (payload: SignInPayload, { rejectWithValue, dispatch }): Promise<SignInResponse | ApiErrorResponse> => {
        try {
            const { data } = await axiosInstance.post('/api/signin/', payload);
            dispatch(authSlice.actions.setTokens(data));
            dispatch(authSlice.actions.setUserData({ phone: payload.phone, name: data.name, username: data.username }));
            // After successful sign-in, call getUserData to fetch authenticated user info
            await dispatch(getUserData());
            return data
        } catch (error: any) {
            return rejectWithValue(error.errors) as any
        }
    }
)

export const signUp = createAsyncThunk('auth/signup',
    // sign up endpoint if success (200 status code ) does not return any data 
    async (payload: SignUpPayload, { rejectWithValue, dispatch }): Promise<ApiErrorResponse | any> => {
        try {
            await axiosInstance.post('/api/signup/', payload);
            const { password, ...userDataWithoutPassword } = payload;
            dispatch(authSlice.actions.setUserData(userDataWithoutPassword));

            return { isOtpSent: true }
        } catch (error: any) {
            return rejectWithValue(error.errors) as any
        }
    }
)

export const getUserData = createAsyncThunk('api-auth/user',

    async (payload, { rejectWithValue, dispatch }): Promise<ApiErrorResponse | any> => {
        try {
            const { data } = await axiosInstance.get('/api-auth/user/');
            dispatch(authSlice.actions.setUserData(data));
            return data;
        } catch (error: any) {
            return rejectWithValue(error.errors) as any
        }
    }
)



export const verifyOtp = createAsyncThunk('auth/verifyOtp',
    async (payload: OtpVerifyPayload, { rejectWithValue, dispatch }): Promise<ApiErrorResponse | OtpVerifyResponse | undefined> => {
        try {
            const response = await axiosInstance.post('/api/signup/confirm/', payload);
            dispatch(authSlice.actions.setTokens(response.data));
            return { ...response.data }

        } catch (error: any) {
            return rejectWithValue(error.errors) as any
        }
    }
)

export const resetPassword = createAsyncThunk('auth/reset-password',
    async (payload: ResetPasswordPayload, { rejectWithValue }): Promise<ApiErrorResponse | OtpVerifyResponse | undefined> => {
        try {
            await axiosInstance.post('/api/reset-password/', payload);

        } catch (error: any) {
            return rejectWithValue(error.errors) as any
        }
    }
)
export const resetPasswordConfirm = createAsyncThunk('auth/reset-password/confirm',
    async (payload: ResetPasswordConfirmPayload, { dispatch, rejectWithValue }): Promise<ApiErrorResponse | OtpVerifyResponse | undefined> => {
        try {
            await axiosInstance.post('/api/reset-password/confirm/', payload);
            dispatch(authSlice.actions.clearErrors())
        } catch (error: any) {
            return rejectWithValue(error.errors) as any
        }
    }
)

export const updateUserData = createAsyncThunk('/api-auth/user/update',
    async (payload: UpdateUserProfilePayload, { dispatch, rejectWithValue }): Promise<ApiErrorResponse | UpdateUserProfilePayload | undefined> => {
        try {
            await axiosInstance.post('/api-auth/user/', payload);
            dispatch(authSlice.actions.clearErrors())
            dispatch(authSlice.actions.setUserData(payload))
        } catch (error: any) {
            return rejectWithValue(error.errors) as any
        }
    }
)



export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState(state) {
            state.memeStockToken = null;
            state.userData = null;
            state.isOtpSent = false;
            state.isPasswordReset = false
            state.isResetPasswordOTPSent = false
            state.isUserLoggedIn = false
            state.errors = null
            localStorage.removeItem('memeStockToken');
            localStorage.removeItem('memeStockAccessToken');
            localStorage.removeItem('memeStockRefreshToken');
        },
        clearAll(state) {
            state.memeStockToken = null;
            state.userData = null;
            state.isOtpSent = false;
            state.isPasswordReset = false
            state.isResetPasswordOTPSent = false
            state.isUserLoggedIn = false
            state.errors = null
            localStorage.removeItem('memeStockToken');
            localStorage.removeItem('memeStockAccessToken');
            localStorage.removeItem('memeStockRefreshToken');
        },
        setTokens: (state, action) => {
            state.memeStockToken = action.payload.idToken;
            localStorage.setItem('memeStockToken', action.payload.idToken);
            localStorage.setItem('memeStockAccessToken', action.payload.accessToken);
            localStorage.setItem('memeStockRefreshToken', action.payload.refreshToken);
        },
        clearTokens: (state) => {
            state.memeStockToken = null;
            localStorage.removeItem('memeStockToken');
            localStorage.removeItem('memeStockAccessToken');
            localStorage.removeItem('memeStockRefreshToken');
        },
        setUserData: (state, action) => {
            state.userData = { ...state.userData, ...action.payload }
        },
        setShowSignInPopup: (state, action) => {
            state.showSignInPopup = action.payload;
        },
        setShowResetPasswordPopup: (state, action) => {
            state.showResetPasswordPopup = action.payload;
        },
        clearErrors: (state) => {
            state.errors = null
        },
        clearPasswordResetState(state) {
            state.isOtpSent = false;
            state.isPasswordReset = false;
            state.isResetPasswordOTPSent = false
            state.errors = null
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(signUp.pending, (state) => {
                state.isLoading = true;
                state.errors = null;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.errors = null;
                state.isOtpSent = (action.payload as any).isOtpSent
            })
            .addCase(signUp.rejected, (state, action) => {
                state.isLoading = false;
                (state.errors) = action.payload as ApiErrorResponse;
            })

            .addCase(verifyOtp.pending, (state) => {
                state.isLoading = true;
                state.errors = null;
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isUserLoggedIn = true;
                state.errors = null;
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.isLoading = false;
                state.isUserLoggedIn = false;
                state.errors = action.payload as ApiErrorResponse;
            })
            .addCase(signIn.pending, (state) => {
                state.isLoading = true;
                state.errors = null;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isUserLoggedIn = true;
                state.errors = null;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.isLoading = false;
                state.isUserLoggedIn = false;
                state.errors = action.payload as ApiErrorResponse;
            })
            .addCase(resetPassword.pending, (state) => {
                state.isLoading = true;
                state.errors = null;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isResetPasswordOTPSent = true;
                state.errors = null;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.isLoading = false;
                state.isResetPasswordOTPSent = false;
                state.errors = action.payload as ApiErrorResponse;
            })
            .addCase(resetPasswordConfirm.pending, (state) => {
                state.isLoading = true;
                state.errors = null;
            })
            .addCase(resetPasswordConfirm.fulfilled, (state, action) => {
                state.isLoading = false;
                state.errors = null;
                state.isPasswordReset = true
            })
            .addCase(resetPasswordConfirm.rejected, (state, action) => {
                state.isLoading = false;
                state.errors = action.payload as ApiErrorResponse;
            })
            .addCase(updateUserData.pending, (state) => {
                state.isLoading = true;
                state.errors = null;
            })
            .addCase(updateUserData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isUserDataUpdated = true;
                state.errors = null;
            })
            .addCase(updateUserData.rejected, (state, action) => {
                state.isLoading = false;
                state.isUserDataUpdated = false;
                state.errors = action.payload as ApiErrorResponse;
            })
    }
})


export default authSlice.reducer;