import { format } from "date-fns";
import { ITickerIntervalsEnum, TickerObject } from "../../../app/types/tickers";
import { mock } from "../../mock";
import { dummyTickerDetails, dummyTickerWindowLimits } from "./data";
import { utcDate } from "../../../helpers/date";



function createData({ symbol, price, sentiment, contributorCount, submissionCount, symbolLabel, name }: TickerObject): any {
    return { symbol, price, sentiment, contributorCount, submissionCount, symbolLabel, name };
}

const cryptoTrickersRows: Array<TickerObject> = [
    createData({ symbol: 'GEM', symbolLabel: undefined, price: 208.14, sentiment: "really_bullish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: '', symbolLabel: 'GEM', price: 208.14, sentiment: "bullish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: 'GEM', symbolLabel: undefined, price: 208.14, sentiment: "neutral", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: 'GEM', symbolLabel: undefined, price: 208.14, sentiment: "bearish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: 'GEM', symbolLabel: undefined, price: 208.14, sentiment: "really_bearish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: 'MSFT', symbolLabel: undefined, price: 208.14, sentiment: "bullish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
];

const stockTrickersRows: Array<TickerObject> = [
    createData({ symbol: 'AAPL', symbolLabel: undefined, price: 208.14, sentiment: "really_bullish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: '', symbolLabel: 'GEM', price: 208.14, sentiment: "bullish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: 'AMZN', symbolLabel: undefined, price: 208.14, sentiment: "neutral", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: 'AMZN', symbolLabel: undefined, price: 208.14, sentiment: "bearish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: 'AMZN', symbolLabel: undefined, price: 208.14, sentiment: "really_bearish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
    createData({ symbol: 'MSFT', symbolLabel: undefined, price: 208.14, sentiment: "bullish", contributorCount: 1324171354, submissionCount: 3287263, name: "test" }),
];


mock.onGet(new RegExp(`/api/tickers/stocks/*`)).reply(() => {
    return [200, {
        "tickerTotal": stockTrickersRows.length,
        "tickerList": stockTrickersRows
    }]
});

mock.onGet(new RegExp(`/api/tickers/crypto/*`)).reply(() => {
    return [200, {
        "tickerTotal": cryptoTrickersRows.length,
        "tickerList": cryptoTrickersRows
    }]
})

const tickerDetailsRegex = new RegExp('^/api-auth/ticker/([^/]+)/details\\?timeInterval=([^&]+)$');
mock.onGet(tickerDetailsRegex).reply((config) => {
    const match = config.url?.match(tickerDetailsRegex);
    if (match) {
        const tickerSymbol = match[1];
        const timeInterval = match[2] as ITickerIntervalsEnum;
        return [200, dummyTickerDetails[timeInterval]]
    }
    return [200, []]
});


const tickerWindowLimitsRegex = new RegExp('^/api-auth/ticker/([^/]+)/submit/window-limits\\?timeInterval=([^&]+)$');
mock.onGet(tickerWindowLimitsRegex).reply((config) => {
    const match = config.url?.match(tickerWindowLimitsRegex);
    if (match) {
        const tickerSymbol = match[1];
        const timeInterval = match[2] as ITickerIntervalsEnum;
        return [200, dummyTickerWindowLimits[timeInterval]]
    }
    return [200, []]
});


const makePredictionRegex = new RegExp('^/api-auth/user/sentiment');
mock.onPost(makePredictionRegex).reply((config) => {
    const requestData = JSON.parse(config.data) as { date: string; sentimentPrice: number; tickerSymbol: string };
    dummyTickerDetails[ITickerIntervalsEnum.ONE_WEEK].displayData.forEach(d => {
        if (format(utcDate(d.date), 'Y-MM-dd') === requestData.date) {
            d.date = requestData.date;
            d.userSubmission = String(requestData.sentimentPrice)
        }
    })
    return [201, {
        message: 'Prediction made successful!y',
    }];

});