import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const RootLayout = () => {
    return (
        <Fragment>
            <ScrollToTop />
            <Outlet />
        </Fragment>
    );
};

export default RootLayout;