import { createBrowserRouter, Navigate, useParams } from "react-router-dom";
import { ROUTES } from "./routes";
import GlobalLoader from "../components/GlobalLoader";
import { lazy } from "react";
import PrivateRoute from "../components/PrivateRoute";
import { useAppSelector } from "./hooks";
import RootLayout from "../components/RootLayout";

const HomePage = lazy(() => import("../pages"));
const TickerDetailsPage = lazy(() => import("../pages/tickers/[id]"));
const Profile = lazy(() => import("../pages/profile"));

const ProfileRoute = () => {
  // Get the dynamic username from the URL params
  const { username: paramsUsername } = useParams();

  // Get the logged-in username from the Redux state
  const username = useAppSelector((state) => state.auth.userData?.username);

  // If no user is logged in, redirect to home
  if (!username) {
    return <GlobalLoader><></></GlobalLoader>;
  }

  if (!paramsUsername) return <Navigate to={`/profile/${username}`} replace />;

  return <Profile />;
};


export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: ROUTES.index,
        element: (
          <GlobalLoader>
            <HomePage />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.tickers.index,
        element: (
          <GlobalLoader>
            <HomePage />
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.tickers.byId(),
        element: (
          <GlobalLoader>
            <PrivateRoute>
              <TickerDetailsPage />
            </PrivateRoute>
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.profile.index,
        element: (
          <GlobalLoader>
            <PrivateRoute>
              <ProfileRoute /> {/* Redirect to /profile/username */}
            </PrivateRoute>
          </GlobalLoader>
        ),
      },
      // Route for "/profile/:username" with dynamic username parameter
      {
        path: ROUTES.profile.byUsername(":username"),
        element: (
          <GlobalLoader>
            <PrivateRoute>
              <ProfileRoute />  {/* Validate and handle redirects */}
            </PrivateRoute>
          </GlobalLoader>
        ),
      },
      {
        path: ROUTES.wildCard,
        element: (
          <GlobalLoader>
            <PrivateRoute>
              <Navigate to="/" />  {/* Redirect to home or a custom 404 page */}
            </PrivateRoute>
          </GlobalLoader>
        ),
      },
    ]
  }
]);